/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div class="bg-gray-900">
      <Header siteTitle={data.site.siteMetadata.title} />
      <div class="container mx-auto">
        <main>{children}</main>
      </div>
      <footer class="bg-gray-700 shadow-xl">
        <div class="container mx-auto p-3">
          <p class="text-sm text-gray-100">
            © {new Date().getFullYear()}, pls
            {` `}
            <a class="underline" href="https://nobul.li">
              nobul.li
            </a>
          </p>
        </div>
      </footer>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
