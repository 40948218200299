import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import "./layout.css"

const Header = ({ siteTitle }) => (
  <header class="bg-gray-700 shadow-xl">
    <div class="container mx-auto p-6">
      <h1 class="text-xl text-gray-100">
        <Link to="/" class="no-underline">
          {siteTitle}
        </Link>
      </h1>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
